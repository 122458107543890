import {NewSideMenuModal, DeleteButton, CreateButton, DefaultSideMenuItem, Placeholder} from 'common/components'
import {useModalContext} from 'components/common'
import {ModalForm} from 'components/form/index'
import {PlanningTemplate} from 'models'
import {useEffect} from 'react'
import {Input} from '@eitje/form-fields-web'
import {navigateModal} from 'actions/routing'
import {useRoleOrgEnvs} from 'hooks'
import {FormRow} from 'components/ui'
import {t} from 'initializers/i18n'
const opts = {keepBackground: true}

const EditTemplate = ({item}) => {
	const insertAtIndex = {
		0: <CreateButton colorSet="grey-bordered" onClick={() => navigateModal(`/planning_templates/save?create=true`, opts)} />,
		1: <DeleteButton onDel={() => item.destroy()} />,
	}
	return (
		<ModalForm
			modalProps={{
				insertAtIndex,
				okButtonProps: {
					t: 'common.load',
					iconLeft: 'copy',
					onClick: () => navigateModal(`/planning_templates/load?planning_template_id=${item.id}`, opts),
				},
				hideCancel: true,
			}}
			initialValues={item}
			transNamespace="planning_templates"
			onSubmit={data => item.update(data)}
		>
			<FormRow>
				<Input required field="naam" submitStrategy="throttledChange" />
			</FormRow>
		</ModalForm>
	)
}

export const ManagePlanningTemplates = () => {
	const temps = PlanningTemplate.all().map(r => r.withEnv())

	const groupedTemps = temps.groupBy('envStatus')
	const managerEnvs = useRoleOrgEnvs('manager')
	let routes = managerEnvs
		.sortBy(i => i.naam.toLowerCase())
		.map(env => {
			const temps = groupedTemps[env.naam] || []
			return makeRoute(temps, env.naam)
		})
	const multiTemps = temps.filter(t => t.environments.length > 1)
	if (multiTemps.length > 0) {
		routes = [...routes, makeRoute(multiTemps, t('common.multiple_venues'))]
	}

	const sideMenuProps = {
		showSearch: true,
		MenuItem: ({isSub, ...props}) => (isSub ? <DefaultSideMenuItem {...props} /> : <ItemWithCount {...props} />),
	}

	return (
		<NewSideMenuModal
			sideMenuProps={sideMenuProps}
			okButtonProps={{onClick: () => alert('yooo')}}
			name="planning_templates"
			routes={routes}
			width={650}
		/>
	)
}

const ItemWithCount = ({children, text}) => {
	return (
		<p>
			{text} ({children.length})
		</p>
	)
}

const TemplatePlaceholder = () => {
	const insertAtIndex = {
		1: <CreateButton onClick={() => navigateModal('/planning_templates/save?create=true', opts)} />,
	}
	const {setModalPropsRaw} = useModalContext()
	// TODO: make hook to set modal props so we don't have to call the rather tedious useEffect
	useEffect(
		() =>
			setModalPropsRaw({
				insertAtIndex,
			}),
		[],
	)
	return (
		<Placeholder docsId="6009519" name="planning_templates" animation="roster" layoutProps={{height: 'unset'}}>
			<CreateButton blockNamespace onClick={() => navigateModal('/planning_templates/save?create=true', opts)} />
		</Placeholder>
	)
}

const makeRoute = (items, name) => {
	items = items._filter('naam')
	return {
		name,
		component: items.length == 0 ? TemplatePlaceholder : null,
		subRoutes: items
			.sortBy(i => i.naam.toLowerCase())
			.map(i => ({
				name: i.nameWithPeriod,
				component: EditTemplate,
				path: i.id,
				item: i,
			})),
	}
}
