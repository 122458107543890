import {CreateButton, NewSideMenuModal} from 'common/components'
import {useOrgEnvsSettingGroup, useRoleEnvs} from 'hooks'
import {MealForm} from './form'
import {Placeholder} from 'common/components'

export const MealsIndex = () => {
	const envs = useRoleEnvs('admin')
	const settings = useOrgEnvsSettingGroup('maaltijd')
	const routes = envs.map(e => {
		const opts = settings[e.id].maaltijd_opts
		const component = opts.length == 0 ? MealPlaceholder : null
		const subRoutes = opts.map(s => ({
			path: s,
			name: s,
			envId: e.id,
			settings: settings[e.id],
			component: MealForm,
		}))
		return {name: e.naam, path: e.id, subRoutes, component}
	})

	return <NewSideMenuModal width={600} name="settings.meals" basePath="settings/meals" routes={routes} />
}

const MealPlaceholder = ({path}) => {
	return (
		<Placeholder name="meals" animation="apple">
			<CreateButton blockNamespace t="common.meal" modalLink={`/settings/meals/new?environment_id=${path}`} />
		</Placeholder>
	)
}
