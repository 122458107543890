import {NewSideMenuModal} from 'common/components'
import {useRoleOrgEnvs} from 'hooks'
import {StartDate} from './setup_start_date'
import {SetupLeave} from './setup_leave'
import {SetupOvertime} from './setup_overtime'

export const SetupBalance = () => {
	const envs = useRoleOrgEnvs('uren_accorderen')
	const routes = envs.map(e => ({
		name: e.naam,
		item: e,
		path: e.id,
		subRoutes: [
			{
				name: 'start_date',
				component: StartDate,
				item: e,
			},
			{
				name: 'overtime_balance',
				component: SetupOvertime,
				item: e,
			},
			{
				name: 'leave_balance',
				component: SetupLeave,
				item: e,
			},
		],
	}))
	return (
		<NewSideMenuModal
			width={750}
			initialRoute={500}
			className="setup-balance-modal"
			name="balance/setup"
			basePath="balance/setup"
			routes={routes}
		/>
	)
}
